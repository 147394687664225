<template>
  <div>
    <b-sidebar
      id="closuresAdd"
      v-model="showClosuresAdd"
      shadow
      backdrop
      no-header
      sidebar-class="sidebar-lg"
      bg-variant="white"
      right
      @hidden="$emit('close'); resetForm()"
    >
      <b-card-body>
        <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
        >
          <b-form
            id="closure-form"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <validation-provider
              #default="validationContext"
              name="closure date"
              :rules="{ required: true }"
            >
              <b-form-group label="Closure Date">
                <b-form-datepicker
                  v-model="closure.date"
                  :state="getValidationState(validationContext)"
                  :min="moment().add(1, 'days').toDate()"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex mt-2">
              <spinner-button
                variant="primary"
                type="submit"
                form="closure-form"
                class="mr-2"
                :loading="saving"
              >
                Save
              </spinner-button>
              <b-button
                @click="showClosuresAdd = false"
              >
                Go Back
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCardBody,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import {
  required, minTime, maxTime,
} from '@validations'
import store from '@/store'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BCardBody,
    BForm,
    BFormGroup,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,

    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    locationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    required,
    minTime,
    maxTime,
    moment,
  }),
  setup(props) {
    // Variables
    const toast = useToast()
    const { locationId } = props
    const showClosuresAdd = ref(false)

    const baseClosure = {
      date: null,
    }

    const closure = ref(JSON.parse(JSON.stringify(baseClosure)))
    const saving = ref(false)

    // Functions
    const open = () => {
      showClosuresAdd.value = true
    }

    const onSubmit = () => {
      store.dispatch('app-location/addClosure', { locationId, date: closure.value.date })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully added this closure',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          showClosuresAdd.value = false
        }).catch(error => {
          let message = 'Something went wrong trying to save this closure please try again later'
          if (error.response && error.response.data && error.response.data.error) {
            message = error.response.data.error
          }

          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const resetClosure = () => {
      closure.value = JSON.parse(JSON.stringify(baseClosure))
    }

    const {
      resetForm, refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation(resetClosure)

    return {
      showClosuresAdd,
      closure,
      saving,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      resetForm,

      open,

      onSubmit,
    }
  },
}
</script>
