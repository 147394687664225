<template>
  <div>
    <b-sidebar
      id="hoursAdd"
      v-model="showHoursAdd"
      shadow
      backdrop
      no-header
      sidebar-class="sidebar-lg"
      bg-variant="white"
      right
      @hidden="$emit('close'); resetForm()"
    >
      <b-card-body>
        <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
        >
          <b-form
            id="hours-form"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- Day of The Week -->
            <validation-provider
              #default="validationContext"
              name="day of the week"
              :rules="{ required: true }"
            >
              <b-form-group label="Day Of The Week">
                <v-select
                  v-model="hours.dayOfTheWeek"
                  :options="dayOptions"
                  label="label"
                  :reduce="day => day.value"
                  class="form-control p-0"
                  :class="[getValidationState(validationContext) == null ? 'border-0': '', getValidationStateNonBootstrap(validationContext)]"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="start time"
                  :rules="{ required: true, max_time: hours.endTime }"
                >
                  <b-form-group label="Start Time">
                    <b-form-timepicker
                      v-model="hours.startTime"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="end time"
                  :rules="{ required: true, min_time: hours.startTime }"
                >
                  <b-form-group label="End Time">
                    <b-form-timepicker
                      v-model="hours.endTime"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex mt-2">
              <spinner-button
                variant="primary"
                type="submit"
                form="hours-form"
                class="mr-2"
                :loading="saving"
              >
                Save
              </spinner-button>
              <b-button
                @click="showHoursAdd = false"
              >
                Go Back
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCardBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormTimepicker,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import {
  required, minTime, maxTime,
} from '@validations'
import store from '@/store'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,

    SpinnerButton,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  props: {
    locationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    required,
    minTime,
    maxTime,
  }),
  setup(props) {
    // Variables
    const toast = useToast()
    const hoursAddForm = ref(null)
    const dayOptions = [
      {
        label: 'Sunday',
        value: 'sunday',
      },
      {
        label: 'Monday',
        value: 'monday',
      },
      {
        label: 'Tuesday',
        value: 'tuesday',
      },
      {
        label: 'Wednesday',
        value: 'wednesday',
      },
      {
        label: 'Thursday',
        value: 'thursday',
      },
      {
        label: 'Friday',
        value: 'friday',
      },
      {
        label: 'Saturday',
        value: 'saturday',
      },
    ]
    const { locationId } = props
    const showHoursAdd = ref(false)

    const baseHours = {
      dayOfTheWeek: null,
      startTime: null,
      endTime: null,
    }

    const hours = ref(JSON.parse(JSON.stringify(baseHours)))
    const saving = ref(false)

    // Functions
    const open = () => {
      showHoursAdd.value = true
    }

    const onSubmit = () => {
      saving.value = true
      const format = 'H:mm:ss'
      const start = moment(hours.value.startTime, format)
      const end = moment(hours.value.endTime, format)
      const params = {
        day_of_week: hours.value.dayOfTheWeek,
        open_time: start.format('HH:mm'),
        close_time: end.format('HH:mm'),
      }

      store.dispatch('app-location/addHours', { locationId, params })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully added the hours',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          showHoursAdd.value = false
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when saving these hours please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const resetHours = () => {
      hours.value = JSON.parse(JSON.stringify(baseHours))
      // eslint-disable-next-line no-unused-expressions
      hoursAddForm.value?.reset()
    }

    const {
      resetForm, refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation(resetHours)

    return {
      hoursAddForm,
      dayOptions,
      showHoursAdd,
      hours,
      saving,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      resetForm,

      open,

      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
