<template>
  <div>
    <b-jumbotron
      bg-variant="primary"
      text-variant="light"
    >
      <template v-slot:header>
        <span class="text-light">
          {{ location.location_name }}
        </span>
      </template>
      <template v-slot:lead>
        {{ location.location_street }} {{ location.location_city }}, {{ location.location_state }} {{ location.location_zip_code }}
      </template>
    </b-jumbotron>

    <b-row align-v="stretch">

      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div
            class="mb-0"
          >
            <div class="d-flex justify-content-between container mt-1 align-items-center">
              <h4>
                Quota Overrides
              </h4>
              <b-button
                variant="primary"
                @click="$refs.overrideSidebar.open()"
              >
                Add Quota Override
              </b-button>
            </div>
            <validation-provider
              ref="quota"
              #default="validationContext"
              name="default override"
              rules="required|min_value:1"
            >
              <b-form-group
                label="Daily Order Limit"
                class="container"
              >
                <b-input-group>
                  <b-form-input
                    v-model="location.location_default_daily_order_limit"
                    type="number"
                    min="0"
                    :state="getValidationState(validationContext)"
                  />

                  <b-input-group-append>
                    <spinner-button
                      variant="primary"
                      :loading="updatingQuota"
                      small
                      @click="updateQuota"
                    >
                      Update
                    </spinner-button>
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <b-table
            :items="overrides"
            :fields="overridesFields"
            responsive
            show-empty
            empty-text="No quota overrides found"
            :busy="loadingQuotaOverrides"
          >
            <template v-slot:cell(date)="data">
              {{ data.item.order_quota_override_date | dateFormat }}
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="confirmRemove(data.item.order_quota_override_id, 'override')"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div
            class="mb-0"
          >
            <div class="d-flex justify-content-between p-1 align-items-center">
              <h4>
                Closures
              </h4>
              <b-button
                variant="primary"
                @click="openClosureSidebar"
              >
                Add Closure
              </b-button>
            </div>
          </div>
          <b-table
            :items="closures"
            :fields="closureFields"
            show-empty
            empty-text="No closures available"
            responsive
            primary-key="closure-id"
            :busy="loadingClosures"
          >
            <template v-slot:cell(date)="data">
              {{ data.item.location_hours_date | dateFormat }}
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="confirmRemove(data.item.location_hours_id, 'closure')"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div
            class="mb-0"
          >
            <div class="d-flex justify-content-between p-1 align-items-center">
              <h4>
                Hours
              </h4>
              <b-button
                variant="primary"
                @click="openHoursSidebar"
              >
                Add Hours
              </b-button>
            </div>
          </div>
          <b-table
            :items="hours"
            :fields="hourFields"
            show-empty
            empty-text="No hours available"
            responsive
            primary-key="closure-id"
            :busy="loadingHours"
          >
            <template v-slot:cell(dayOfTheWeek)="data">
              <span class="text-capitalize">
                {{ data.item.location_hours_day_of_week }}
              </span>
            </template>

            <template v-slot:cell(hours)="data">
              {{ timeForHours(data.item) }}
            </template>

            <template v-slot:cell(actions)="data">
              <b-button
                variant="outline-danger"
                size="sm"
                @click="confirmRemove(data.item.location_hours_id, 'hours')"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <div
            class="mb-0"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h4>
                Other
              </h4>
            </div>
          </div>

          <validation-provider
            ref="minValidator"
            #default="validationContext"
            name="order placement min lead days"
            :rules="{ required: true, max_value: location.location_order_placement_max_lead_days - 1 }"
          >
            <b-form-group label="Order Placement Min Lead Days">
              <b-input-group>
                <b-form-input
                  v-model="location.location_order_placement_min_lead_days"
                  type="number"
                  min="0"
                  number
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append v-if="showPlacementUpdateButton(PLACEMENT_TYPE_MIN)">
                  <spinner-button
                    ref="minButton"
                    variant="primary"
                    small
                    @click="updateOrderPlacementLeadDays(PLACEMENT_TYPE_MIN, location.location_order_placement_min_lead_days)"
                  >
                    Update
                  </spinner-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            ref="maxValidator"
            #default="validationContext"
            name="order placement max lead days"
            :rules="{ required: true, min_value: location.location_order_placement_min_lead_days + 1 }"
          >
            <b-form-group label="Order Placement Max Lead Days">
              <b-input-group>
                <b-form-input
                  v-model="location.location_order_placement_max_lead_days"
                  type="number"
                  min="0"
                  number
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append v-if="showPlacementUpdateButton(PLACEMENT_TYPE_MAX)">
                  <spinner-button
                    ref="maxButton"
                    variant="primary"
                    small
                    @click="updateOrderPlacementLeadDays(PLACEMENT_TYPE_MAX, location.location_order_placement_max_lead_days)"
                  >
                    Update
                  </spinner-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            ref="cancellationValidator"
            #default="validationContext"
            name="cancellation lead hours"
            rules="required|min_value:1"
          >
            <b-form-group label="Cancellation Lead Hours">
              <b-input-group>
                <b-form-input
                  v-model="location.location_order_cancellation_lead_hours"
                  type="number"
                  min="0"
                  number
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append v-if="showCancellationUpdate">
                  <spinner-button
                    variant="primary"
                    small
                    :loading="updatingCancellationTime"
                    @click="updateCancellationWindow"
                  >
                    Update
                  </spinner-button>
                </b-input-group-append>
              </b-input-group>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            ref="builderDisclaimerTitleValidator"
            #default="validationContext"
            name="order from builder disclaimer title"
            :rules="{ max: 255 }"
          >
            <b-form-group label="Order From Builder Disclaimer Title">
              <b-input-group>
                <b-form-input
                  v-model="location.location_builder_disclaimer_title"
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append v-if="showDisclaimerUpdateButton(DISCLAIMER_TITLE)">
                  <spinner-button
                    ref="disclaimerTitleButton"
                    variant="primary"
                    small
                    @click="updateBuilderDisclaimer(DISCLAIMER_TITLE, location.location_builder_disclaimer_title)"
                  >
                    Update
                  </spinner-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            ref="builderDisclaimerMessageValidator"
            #default="validationContext"
            name="order from builder disclaimer message"
            :rules="{ max: 500 }"
          >
            <b-form-group label="Order From Builder Disclaimer Message">
              <b-input-group>
                <b-form-textarea
                  v-model="location.location_builder_disclaimer_message"
                  :state="getValidationState(validationContext)"
                />
                <b-input-group-append v-if="showDisclaimerUpdateButton(DISCLAIMER_MESSAGE)">
                  <spinner-button
                    ref="disclaimerMessageButton"
                    variant="primary"
                    small
                    @click="updateBuilderDisclaimer(DISCLAIMER_MESSAGE, location.location_builder_disclaimer_message)"
                  >
                    Update
                  </spinner-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card>
      </b-col>
    </b-row>

    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="resetTempId"
    >
      <p class="text-center">
        {{ deleteMessage }}
      </p>
      <template v-slot:footer>
        <b-button
          @click="$refs.confirm.close()"
        >Go Back</b-button>
        <spinner-button
          variant="danger"
          :loading="removing"
          @click="handleRemove"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
    <hours-add-sidebar
      ref="hoursSidebar"
      :location-id="+locationId"
      @close="fetchHours()"
    />
    <closure-add-sidebar
      ref="closureSidebar"
      :location-id="+locationId"
      @close="fetchClosures()"
    />
    <quota-override-sidebar
      ref="overrideSidebar"
      :location-id="locationId"
      @close="fetchQuotaOverrides()"
    />
  </div>
</template>

<script>
import {
  BJumbotron,
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormTextarea,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import locationStoreService from '@/services/locationStoreService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import HoursAddSidebar from '@/views/locations/HoursAddSidebar.vue'
import ClosureAddSidebar from '@/views/locations/ClosureAddSidebar.vue'
import QuotaOverrideSidebar from '@/views/locations/QuotaOverrideSidebar.vue'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, minValue } from '@core/utils/validations/validations'

export default {
  components: {
    BJumbotron,
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormTextarea,
    BFormGroup,
    BFormInvalidFeedback,
    IconModal,
    SpinnerButton,
    HoursAddSidebar,
    ClosureAddSidebar,
    QuotaOverrideSidebar,

    ValidationProvider,
  },
  filters: {
    dateFormat: date => {
      if (date) {
        return moment(date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
      }

      return ''
    },
  },
  data: () => ({
    required,
    minValue,
  }),
  setup() {
    // Store
    const LOCATION_MODULE_KEY = 'app-location'
    if (!store.hasModule(LOCATION_MODULE_KEY)) store.registerModule(LOCATION_MODULE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(LOCATION_MODULE_KEY)) store.unregisterModule(LOCATION_MODULE_KEY, locationStoreService)
    })

    // Constants
    const PLACEMENT_TYPE_MIN = 'min'
    const PLACEMENT_TYPE_MAX = 'max'
    const DISCLAIMER_TITLE = 'title'
    const DISCLAIMER_MESSAGE = 'message'

    // Variables
    const locationId = router.currentRoute.params.location_id
    const location = ref(router.currentRoute.params)
    const closures = ref([])
    const hours = ref([])
    const overrides = ref([])
    const loadingHours = ref(false)
    const loadingClosures = ref(false)
    const loadingQuotaOverrides = ref(false)
    const hourFields = [
      {
        key: 'dayOfTheWeek',
        label: 'Day Of Week',
      },
      {
        key: 'hours',
      },
      {
        key: 'actions',
      },
    ]
    const closureFields = [
      {
        key: 'date',
      },
      {
        key: 'actions',
      },
    ]
    const overridesFields = [
      {
        key: 'date',
      },
      {
        key: 'order_quota_override_value',
        label: 'Quantity',
      },
      {
        key: 'actions',
      },
    ]

    const toast = useToast()
    const tempId = ref({
      id: null,
      type: null,
    })

    const confirm = ref(null)
    const removing = ref(false)

    const hoursSidebar = ref(null)
    const closureSidebar = ref(null)
    const deleteMessage = ref(null)

    const updatingQuota = ref(false)
    const quota = ref(null)

    const cancellationValidator = ref(null)
    const originalCancellationTime = ref(null)
    const updatingCancellationTime = ref(false)

    const minValidator = ref(null)
    const maxValidator = ref(null)
    const builderDisclaimerTitleValidator = ref(null)
    const builderDisclaimerMessageValidator = ref(null)
    const minButton = ref(null)
    const maxButton = ref(null)
    const disclaimerTitleButton = ref(null)
    const disclaimerMessageButton = ref(null)
    const originalLocationData = ref({
      min: null,
      max: null,
      title: null,
      message: null,
    })

    // Computed
    const showCancellationUpdate = computed(() => location.value.location_order_cancellation_lead_hours !== originalCancellationTime.value)

    // Functions
    const fetchLocationDetails = () => {
      store.dispatch('app-location/fetchLocation', { locationId })
        .then(response => {
          location.value = response.data
          originalCancellationTime.value = location.value.location_order_cancellation_lead_hours
          originalLocationData.value = {
            min: location.value.location_order_placement_min_lead_days,
            max: location.value.location_order_placement_max_lead_days,
            title: location.value.location_builder_disclaimer_title,
            message: location.value.location_builder_disclaimer_message,
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching the location details please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const fetchHours = () => {
      loadingHours.value = true
      store.dispatch('app-location/fetchHours', { locationId })
        .then(response => {
          hours.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to fetch hours please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingHours.value = false
        })
    }
    const fetchClosures = () => {
      loadingClosures.value = true
      store.dispatch('app-location/fetchClosures', { locationId })
        .then(response => {
          closures.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to fetch closures please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingClosures.value = false
        })
    }
    const fetchQuotaOverrides = () => {
      loadingQuotaOverrides.value = true
      store.dispatch('app-location/fetchQuotaOverrides', { locationId })
        .then(response => {
          overrides.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching the overrides please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingQuotaOverrides.value = false
        })
    }
    const timeForHours = currentHours => {
      const start = moment(`${currentHours.time_window.time_window_start_hour}:${currentHours.time_window.time_window_start_minutes}`, 'H:mm')
      const end = moment(`${currentHours.time_window.time_window_end_hour}:${currentHours.time_window.time_window_end_minutes}`, 'H:mm')

      return `${start.format('h:mm A')} to ${end.format('h:mm A')}`
    }
    const confirmRemove = (id, type) => {
      if (type === 'hours') {
        deleteMessage.value = 'Are you sure you would to delete these hours? This will not affect existing orders.'
      } else if (type === 'closure') {
        deleteMessage.value = 'Are you sure you would like to delete this closure?'
      } else {
        deleteMessage.value = 'Are you sure you would like to delete this quota override?'
      }

      tempId.value = {
        id,
        type,
      }
      confirm.value.open()
    }
    const resetTempId = () => {
      tempId.value = {
        id: null,
        type: null,
      }
    }
    const removeHours = () => {
      removing.value = true
      store.dispatch('app-location/deleteHours', { locationId, locationHoursId: tempId.value.id })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully deleted the hours',
              icon: 'CircleCheckIcon',
              variant: 'success',
            },
          })

          fetchHours()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when deleting these hours please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          removing.value = false
          confirm.value.close()
        })
    }
    const removeClosure = () => {
      removing.value = true
      store.dispatch('app-location/deleteClosure', { locationId, locationClosureId: tempId.value.id })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully deleted the closure',
              icon: 'CircleCheckIcon',
              variant: 'success',
            },
          })

          fetchClosures()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when deleting this closure please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          removing.value = false
          confirm.value.close()
        })
    }
    const removeOverride = () => {
      removing.value = true
      store.dispatch('app-location/deleteOverride', { locationId, overrideId: tempId.value.id })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully deleted the quota override',
              icon: 'CircleCheckIcon',
              variant: 'success',
            },
          })
          fetchQuotaOverrides()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to delete this quota override please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          removing.value = false
          confirm.value.close()
        })
    }
    const handleRemove = () => {
      if (tempId.value.type === 'hours') {
        removeHours()
      } else if (tempId.value.type === 'closure') {
        removeClosure()
      } else {
        removeOverride()
      }
    }
    const openHoursSidebar = () => {
      hoursSidebar.value.open()
    }
    const openClosureSidebar = () => {
      closureSidebar.value.open()
    }
    const openOverridesModal = () => {
      overrides.value.open()
    }
    const updateQuota = () => {
      quota.value.validate().then(response => {
        if (response.valid) {
          updatingQuota.value = true
          store.dispatch('app-location/updateDailyOrderQuota', { locationId, limit: location.value.location_default_daily_order_limit })
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Successfully updated the daily quota',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              quota.value.reset()
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong trying to update the daily quota please try again later',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              updatingQuota.value = false
            })
        }
      })
    }
    const updateCancellationWindow = () => {
      cancellationValidator.value.validate().then(response => {
        if (response.valid) {
          updatingCancellationTime.value = true
          store.dispatch('app-location/updateCancellationWindow', { locationId, hours: location.value.location_order_cancellation_lead_hours })
            .then(() => {
              originalCancellationTime.value = location.value.location_order_cancellation_lead_hours
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Successfully updated the cancellation lead time',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong trying to update the cancellation lead time please try again later',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              updatingCancellationTime.value = false
              cancellationValidator.value.reset()
            })
        }
      })
    }
    const updateOrderPlacementLeadDays = (type, value) => {
      let validator
      if (type === PLACEMENT_TYPE_MIN) {
        validator = minValidator.value
      } else {
        validator = maxValidator.value
      }

      validator.validate().then(response => {
        if (response.valid) {
          let button
          if (type === PLACEMENT_TYPE_MIN) {
            button = minButton.value
          } else {
            button = maxButton.value
          }

          button.start()
          store.dispatch('app-location/updateOrderLeadTime', { locationId, type, value })
            .then(() => {
              originalLocationData.value[type] = value
              toast({
                component: ToastificationContent,
                props: {
                  title: `Successfully updated the ${type} order lead days`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `Something went wrong trying to update the ${type} order lead days please try again later`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              button.stop()
              validator.reset()
            })
        }
      })
    }

    const updateBuilderDisclaimer = (type, value) => {
      let validator
      if (type === DISCLAIMER_TITLE) {
        validator = builderDisclaimerTitleValidator.value
      } else {
        validator = builderDisclaimerMessageValidator.value
      }

      validator.validate().then(response => {
        if (response.valid) {
          let button
          if (type === DISCLAIMER_TITLE) {
            button = disclaimerTitleButton.value
          } else {
            button = disclaimerMessageButton.value
          }

          button.start()
          store.dispatch('app-location/updateLocationBuilderDisclaimer', { locationId, type, value })
            .then(() => {
              originalLocationData.value[type] = value
              toast({
                component: ToastificationContent,
                props: {
                  title: `Successfully updated the disclaimer ${type}`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `Something went wrong trying to update the disclaimer ${type} please try again later`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally(() => {
              button.stop()
              validator.reset()
            })
        }
      })
    }

    const showPlacementUpdateButton = type => originalLocationData.value[type] !== location.value[`location_order_placement_${type}_lead_days`]

    const showDisclaimerUpdateButton = type => originalLocationData.value[type] !== location.value[`location_builder_disclaimer_${type}`]

    // Validation
    const {
      getValidationState,
    } = formValidation()

    // Initial
    fetchLocationDetails()
    fetchHours()
    fetchClosures()
    fetchQuotaOverrides()

    return {
      PLACEMENT_TYPE_MAX,
      PLACEMENT_TYPE_MIN,
      DISCLAIMER_TITLE,
      DISCLAIMER_MESSAGE,

      locationId,
      location,
      closures,
      hours,
      loadingHours,
      loadingClosures,
      loadingQuotaOverrides,
      hourFields,
      closureFields,
      tempId,
      confirm,
      removing,
      hoursSidebar,
      closureSidebar,
      deleteMessage,
      overrides,
      overridesFields,
      quota,
      updatingQuota,

      showCancellationUpdate,
      updatingCancellationTime,
      cancellationValidator,

      minValidator,
      maxValidator,
      builderDisclaimerTitleValidator,
      builderDisclaimerMessageValidator,
      minButton,
      maxButton,
      disclaimerTitleButton,
      disclaimerMessageButton,

      timeForHours,
      confirmRemove,
      resetTempId,
      handleRemove,
      openHoursSidebar,
      openClosureSidebar,
      openOverridesModal,
      fetchHours,
      fetchClosures,
      fetchQuotaOverrides,
      updateQuota,
      updateCancellationWindow,
      updateOrderPlacementLeadDays,
      updateBuilderDisclaimer,
      showPlacementUpdateButton,
      showDisclaimerUpdateButton,

      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

::v-deep .card {
  height: 50vmax;
  overflow: scroll;

  @include media-breakpoint-up(lg) {
    height: 27vmax;
  }

  & > .table-responsive {
    position: relative;
  }
}

::v-deep thead {
  position: sticky;
  top: 0;
}
</style>
