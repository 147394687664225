<template>
  <div>
    <b-sidebar
      id="overrideAdd"
      v-model="showOverrideAdd"
      shadow
      backdrop
      no-header
      sidebar-class="sidebar-lg"
      bg-variant="white"
      right
      @hidden="$emit('close'); resetForm()"
    >
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
        >
          <b-form
            id="override-form"
            ref="refFormObserver"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <validation-provider
              #default="validationContext"
              name="override date"
              :rules="{ required: true }"
            >
              <b-form-group label="Override Date">
                <b-form-datepicker
                  v-model="override.date"
                  :state="getValidationState(validationContext)"
                  :min="moment().add(1, 'days').toDate()"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="override max orders"
              :rule="{ required: true, min_value: 1 }"
            >

              <b-form-group label="Override Max Orders">
                <b-form-spinbutton
                  v-model="override.maxOrders"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <div class="d-flex mt-2">
              <spinner-button
                variant="primary"
                type="submit"
                form="override-form"
                class="mr-2"
                :loading="saving"
              >
                Save
              </spinner-button>
              <b-button
                @click="showOverrideAdd = false"
              >
                Go Back
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BCardBody,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import {
  required, minTime, maxTime,
} from '@validations'
import store from '@/store'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BCardBody,
    BForm,
    BFormGroup,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormSpinbutton,

    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    locationId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    required,
    minTime,
    maxTime,
    moment,
  }),
  setup(props) {
    // Variables
    const toast = useToast()
    const { locationId } = props
    const showOverrideAdd = ref(false)

    const baseOverride = {
      date: null,
      maxOrders: 1,
    }

    const override = ref(JSON.parse(JSON.stringify(baseOverride)))
    const saving = ref(false)

    // Functions
    const open = () => {
      showOverrideAdd.value = true
    }

    const onSubmit = () => {
      saving.value = true
      const params = {
        locationId,
        date: override.value.date,
        max_orders: override.value.maxOrders,
      }
      store.dispatch('app-location/addOverride', params)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully added this override',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          showOverrideAdd.value = false
        }).catch(error => {
          let message = 'Something went wrong trying to save this override please try again later'
          if (error.response && error.response.data && error.response.data.error) {
            message = error.response.data.error
          }

          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          saving.value = false
        })
    }

    const resetClosure = () => {
      override.value = JSON.parse(JSON.stringify(baseOverride))
    }

    const {
      resetForm, refFormObserver, getValidationState,
    } = formValidation(resetClosure)

    return {
      showOverrideAdd,
      override,
      saving,

      refFormObserver,
      getValidationState,
      resetForm,

      open,

      onSubmit,
    }
  },
}
</script>
